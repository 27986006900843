import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../../intl/useTranslation";
import { sendPin } from "../../../services/authServices";
import { Mixpanel, replaceAll } from "../../../utils";
import BookingHeaderDesktop from "../../bookings/BookingHeaderDesktop";

import { PhoneInput, isValidPhoneNumber } from "TS_components/NewPhoneInput/PhoneInput";
import { Status } from "../../User/Appointment";

function OtpNumber({
  setBookingNumber,
  setPinId,
  page,
  setHasNumber,
  CouponCodeInfo,
  discountName,
  discountVerified,
  bookings,
  visitType,
  isB1G1,
  setVisibility,
  loadState,
  setLoading,
  hasNumber,
  haveInsurance,
  initialValue,
  setError,
  showMargin = true,
  defaultCountry = null,
  isBMGF = false,
  isOTP = true,
  isInBookingPage,
  changeNumberClicked = () => {},
}) {
  // state
  const [errors, setErrors] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");
  const [hideNextButton, setHideNextButton] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { client_side_country, language_code, country_code } = useSelector(({ InitialDataS }) => InitialDataS);

  const realPage = page ? page + " - " : "";

  const submit = async () => {
    grecaptcha.ready(() => {
      grecaptcha.execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, { action: "submit" }).then(async (token) => {
        try {
          if (phoneNum == "") {
            return;
          }
          if (!phoneNum?.status) {
            if (setLoading) {
              setLoading(false);
            }
            if (isInBookingPage) {
              let trackProps = {
                "Doctor Name": bookings.name_en,
                "Doctor Slug": bookings.slug,
                "Speciality Name": bookings.specialization.name_en,
                "is Premium": bookings.premium,
                "is Discount": discountVerified,
                "Visit Type": visitType,
              };
              Mixpanel.track("Booking, OTP Enter Mobile | Mobile Number Invalid | Event", trackProps);
            }
            setIsInvalid(true);
            return;
          }
          if (setLoading) setLoading(true);
          if (setVisibility) setVisibility(false);
          // dispatch(changeLoading(true));

          const data = await sendPin(client_side_country, language_code, replaceAll(phoneNum.number, /\s/g, ""), token);
          // dispatch(changeLoading(false));
          if (setLoading) setLoading(false);
          if (setVisibility) setVisibility(true);
          if (data?.success) {
            // change the tracking event in the submit booking flow to the new event.
            let trackText = `${realPage}OTP Sent to Number`;
            if (realPage === "Submit Booking Page - ") {
              trackText = "Booking, OTP Enter PIN | Page Loaded | Event";
            }
            setBookingNumber(replaceAll(phoneNum.number, /\s/g, ""));
            setHasNumber(true);
            setIsInvalid(false);
            setHideNextButton(true);
            Mixpanel.track(trackText);
            if (data.pinId) {
              setPinId(data.pinId);
            }
          }
        } catch (err) {
          if (setError) {
            setError();
          }
          setErrors(err.data.errors || [err.data.detail]);
          // dispatch(changeLoading(false));
        }
      });
    });
  };

  return (
    <>
      <div class="booking-status-container desk-only">
        <Status
          videoConsultation={visitType === "video"}
          clinicConsultation={visitType === "clinic"}
          isDiscount={discountVerified}
          isB1G1={isB1G1}
          haveInsurance={haveInsurance}
        ></Status>
      </div>
      <BookingHeaderDesktop
        CouponCodeInfo={CouponCodeInfo}
        bookings={bookings}
        discountName={discountName}
        discountVerified={discountVerified}
        visitType={visitType}
        isB1G1={isB1G1}
        isOTP={isOTP}
        isBMGF={isBMGF}
      ></BookingHeaderDesktop>
      {/* <h3 className="form-disclaimer desk-only otp-header-text">{t("Start by entering your mobile number")}</h3>
            <h3 className="form-disclaimer mobile-only otp-header-text">{t("Start by entering your mobile number")}</h3> */}
      <div
        className={`${
          loadState?.loading && !loadState?.transparentLoader ? "form-control-loading" : ""
        } form-box otp-form-container ${!showMargin ? "no-margin-b" : ""}`}
      >
        <span className="fs-14 fw-700">{t("Mobile")}</span>
        <form
          // method="post"
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          {errors.length > 0 ? (
            <div className="alert alert-block alert-danger inherit-width">
              <ul>
                {errors.map((error, index) => (
                  <li key={`error-${index}`}>{error}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <div className={`form-group ${isInvalid ? "has-danger" : ""}`} id="bookingFormGroup">
            {/* potentially missing validation */}

            <div>
              <PhoneInput
                value={phoneNum.number || ""}
                onChange={(val) => {
                  setPhoneNum({ status: isValidPhoneNumber(val), number: val });
                }}
                placeholder={t("Enter your mobile number")}
                disabled={hasNumber}
                defaultCountry={(country_code || "QA").toUpperCase()}
              />
              {hasNumber ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setHasNumber(false);
                    setHideNextButton(false);
                    changeNumberClicked();
                    if (isInBookingPage) {
                      let trackProps = {
                        "Doctor Name": bookings.name_en,
                        "Doctor Slug": bookings.slug,
                        "Speciality Name": bookings.specialization.name_en,
                        "is Premium": bookings.premium,
                        "is Discount": discountVerified,
                        "Visit Type": visitType,
                      };
                      Mixpanel.track("Booking, OTP Enter PIN | Change Number Clicked | Button", trackProps);
                    }
                  }}
                  className="otp-change-number-button"
                >
                  {t("Change number")}
                </button>
              ) : (
                <></>
              )}
            </div>
            {isInvalid && (
              <span className="help-block">
                <strong>{t("Please enter a Valid Phone Number.")}</strong>
              </span>
            )}
          </div>
          {!hideNextButton ? (
            <input
              type="submit"
              name="verify"
              value={t("Next")}
              className={`${
                isValidPhoneNumber(phoneNum?.number || "") ? "booking-otp-cta-enabled" : "booking-otp-cta-disabled"
              } btn btn-primary form-submit-primary otp-number-form-button`}
              id="submit-id-sign_up"
            />
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  );
}

export default OtpNumber;
