import useTranslation from "../../../intl/useTranslation";

function Status({
    statusEn,
    videoConsultation,
    hideStatuses,
    clinicConsultation,
    audioConsultation,
    homeConsultation,
    status,
    isB1G1,
    isB1G1Booking,
    isFlexWrap,
    isDiscount,
    haveInsurance
}) {
    const { t } = useTranslation();

    return (
        <>
            <div className={`status-container ${isFlexWrap? "status-container-wrap": ""}`}>
                {statusEn == "Cancelled" && !hideStatuses && (
                    <div className="cancelled-appointment">
                        <span>{status}</span>
                    </div>
                )}
                {statusEn == "Confirmed" && !hideStatuses && (
                    <div className="confirmed-appointment">
                        <span>{status}</span>
                    </div>
                )}
                {statusEn == "Pending" && !hideStatuses && (
                    <div className="pending-appointment">
                        <span>{status}</span>
                    </div>
                )}
                {videoConsultation && (
                    <div className="consultation-status video">
                        <img src="/images/icon-video-white.svg" alt="Video Consultation" />
                        <span>{t("Video Visit")}</span>
                    </div>
                )}
                {clinicConsultation && (
                    <div className="consultation-status clinic">
                        <img src="/images/icon-clinic-white.svg" alt="Clinic Consultation" />
                        <span>{t("Clinic Visit")}</span>
                    </div>
                )}
                {audioConsultation && (
                    <div className="consultation-status audio">
                        <img src="/images/icon-hangup-white.svg" alt="Audio Consultation" />
                        <span>{t("Audio Visit")}</span>
                    </div>
                )}
                {homeConsultation && (
                    <div className="consultation-status home">
                        <img src="/images/icon-home-white.svg" alt="Home Consultation" />
                        <span>{t("Home Visit")}</span>
                    </div>
                )}
                { (isB1G1 || isB1G1Booking) && 
                    <div className="consultation-status discount">
                         <img src="/next-images/Icon_Discount.svg" alt="Buy 1 Get 1" />
                         <span>{t("Buy1Get1Free")}</span>
                    </div>
                }
                { (!isB1G1 && !isB1G1Booking && isDiscount) && 
                    <div className="consultation-status discount">
                         <img src="/next-images/Icon_Discount.svg" alt="Discount" />
                         <span>{t("Discount")}</span>
                    </div>
                }
                { haveInsurance ?
                    <div className="consultation-status insurance">
                         <span>{t("insurance available")}</span>
                    </div> : <></>
                }
            </div>
        </>
    );
}

export default Status;
