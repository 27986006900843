import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import useTranslation from "../../../intl/useTranslation";
import { removeImage, uploadDoc } from "../../../data/actions/completeProfile";
import { downloadFile } from "../../../services/completeProfile";
import { localize } from "../../../utils";

function UploadInsurance({ insuranceDocument, noDocMsg, showNext, totalSteps, setErr }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { language_code, client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);

    const { push } = useRouter();
    const goToNext = () => push(`/${localize("me/appointments", client_side_country, language_code)}`);

    return (
        <>
            <div className="container container-inner">
                <div className={`upload ${!insuranceDocument ? "empty" : ""}`}>
                    {insuranceDocument ? (
                        <div className="upload-item card uploaded-item">
                            <div className={`img-container ${!insuranceDocument.is_image ? "file-upload" : ""}`}>
                                <img
                                    src={insuranceDocument.is_image ? insuranceDocument.url : "/images/file.svg"}
                                    alt="Insurance Image"
                                    className="uploaded-passport"
                                />
                                {!insuranceDocument.is_image && (
                                    <a href={insuranceDocument.url} target="_blank" title={insuranceDocument.file_name}>
                                        {insuranceDocument.file_name}
                                    </a>
                                )}
                            </div>
                            <div
                                onClick={async () => await dispatch(removeImage(insuranceDocument, "insurance", null))}
                                className="img-action remove-img"
                            >
                                <img src="/images/icon-close.svg" alt="Remove Image" />
                            </div>
                            <div
                                onClick={async () => await downloadFile(insuranceDocument, setErr)}
                                className="img-action download-img"
                            >
                                <img src="/images/Icon_Download Circle.svg" alt="Download File" />
                            </div>
                            <div className="upload-again">
                                <input
                                    type="file"
                                    accept="application/pdf,image/*"
                                    id="insurance-image"
                                    style={{ display: "none" }}
                                    onChange={async (e) => dispatch(uploadDoc(e, "insurance", null))}
                                />
                                <div onClick={() => document.getElementById("insurance-image").click()}>
                                    <img src="/images/icon-try-again.svg" alt="Upload Again" />
                                    <span>{t("Upload Again")}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <img src="/images/icon-upload.svg" alt="Upload Img" />
                            <h2>{t(noDocMsg)}</h2>
                        </div>
                    )}
                </div>
                {!insuranceDocument && showNext && (
                    <p className="skip-title">
                        <span onClick={goToNext}>{t("Skip for now")}</span>
                    </p>
                )}
            </div>
            <div className="desk-only sticky-bottom footer complete-profile-footer">
                <div className="container">
                    <div>
                        {!insuranceDocument ? (
                            <>
                                <input
                                    type="file"
                                    accept="application/pdf,image/*"
                                    id="insurance-image"
                                    style={{ display: "none" }}
                                    onChange={async (e) => dispatch(uploadDoc(e, "insurance", null))}
                                />
                                <input
                                    type="button"
                                    value={t("Upload Insurance")}
                                    onClick={() => document.getElementById("insurance-image").click()}
                                    className="button-default-md inherit-width upload-btn"
                                />
                            </>
                        ) : null}

                        {insuranceDocument && showNext && (
                            <button
                                type="submit"
                                className="button-default-md default-dark inherit-width"
                                onClick={goToNext}
                            >
                                {t("Finish")}
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="mobile-only sticky-bottom footer extra-margin-footer">
                <div>
                    {!insuranceDocument && (
                        <>
                            <input
                                type="file"
                                accept="application/pdf,image/*"
                                id="insurance-image"
                                style={{ display: "none" }}
                                onChange={async (e) => dispatch(uploadDoc(e, "insurance", null))}
                            />
                            <input
                                type="button"
                                value={t("Upload Insurance")}
                                onClick={() => document.getElementById("insurance-image").click()}
                                className="button-default-md inherit-width upload-btn"
                            />
                        </>
                    )}

                    {insuranceDocument && showNext && (
                        <button
                            type="submit"
                            className="button-default-md default-dark inherit-width"
                            onClick={goToNext}
                        >
                            {totalSteps > 2 ? t("Next Step") : t("Finish")}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

export default UploadInsurance;
