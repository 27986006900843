import useTranslation from "../../../intl/useTranslation";

function InfoSidebar() {
    const { t } = useTranslation();

    return (
        <>
            <div className="col-xs-3 appointments-sidebar mb-5 desk-only">
                <h3 className="sidebar-header"> {t("Why book through HeliumDoc?")} </h3>
                <hr className="secondary-line" />
                <div className="row d-flex mt-2">
                    <div className="col-sm-2 col-xs-2">
                        <img className="demo-image-mobile" src="/images/instant-appointment.svg" alt="No alt" />
                    </div>
                    <div className="col-sm-10 col-xs-10 info-wrapper">
                        <h5 className="mt-0 info-wrapper-header">{t("Instant Appointments")}</h5>
                        <p className="small text-secondary">
                            {t("Get all information about a doctor instantly without calling the clinic.")}
                        </p>
                    </div>
                </div>
                <div className="row d-flex mt-1">
                    <div className="col-sm-2 col-xs-2">
                        <img className="demo-image-mobile" src="/images/exiting.svg" alt="No alt" />
                    </div>
                    <div className="col-sm-10 col-xs-10 info-wrapper">
                        <h5 className="mt-0 info-wrapper-header">{t("Exciting Offers")}</h5>
                        <p className="small text-secondary">
                            {t("Get exciting discounts and offers with doctors when booking with HeliumDoc!")}
                        </p>
                    </div>
                </div>
                <div className="row d-flex mt-1">
                    <div className="col-sm-2 col-xs-2">
                        <img className="demo-image-mobile" src="/images/free-cancellation.svg" alt="No alt" />
                    </div>
                    <div className="col-sm-10 col-xs-10 info-wrapper">
                        <h5 className="mt-0 info-wrapper-header">{t("Free Cancellation")}</h5>
                        <p className="small text-secondary">
                            {t(
                                "We get it - sometime things come up and you have to decline an appointment, you can re-book anytime the same appointment from your profile."
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoSidebar;
