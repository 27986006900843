import { API, localize } from '../utils';

// Send PIN Api
export const sendPin = async (client_side_country, language_code, number, token) => {
  try {
    const data = await API.postRequest(true, localize(`pin/`, client_side_country, language_code), {
      number,
      'g-recaptcha-response': token,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

// resend PIN Api
export const resendPinApi = async (client_side_country, language_code, pinId, token, phone_number, isWhatsapp) => {
  try {
    const data = await API.postRequest(true, localize(`resend/`, client_side_country, language_code), {
      pin_id: pinId,
      phone_number,
      'g-recaptcha-response': token,
      ...(isWhatsapp
        ? {
            channel: 'WhatsApp',
          }
        : {}),
    });

    return data;
  } catch (err) {
    throw err;
  }
};

export const changePhoneNumber = async (pinId, pin, bookingNumber) => {
  try {
    const res = await API.postRequest(true, 'profile/change-phone-number/', {
      pin_id: pinId,
      pin,
      phone: bookingNumber ? bookingNumber : undefined,
    });

    return res;
  } catch (err) {
    return err;
  }
};

// verify change number by PIN
export const otpLogin = async (pinId, pin, bookingNumber, email) => {
  try {
    const data = await API.postRequest(true, 'api/otp-login/', {
      pin_id: pinId,
      pin,
      phone: bookingNumber ? bookingNumber : undefined,
      email: email ? email : undefined,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

// not tested need more handle
export const signup = async (pinId, pin, bookingNumber, email) => {
  try {
    const data = await API.postRequest(true, '/api/signup/', {
      pin_id: pinId,
      pin,
      phone: bookingNumber ? bookingNumber : undefined,
      email: email ? email : undefined,
    });
    return data;
  } catch (err) {
    throw err;
  }
};
