import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCountdown } from "usehooks-ts";
import { GetInitialData } from "../../../data/actions/init";
import { changeLoading } from "../../../data/actions/loading";
import useTranslation from "../../../intl/useTranslation";
import { changePhoneNumber, otpLogin, resendPinApi, signup } from "../../../services/authServices";
import { Mixpanel } from "../../../utils";

function OtpVerify({
  setHasNumber,
  bookingNumber,
  page,
  canUsePassword,
  canChooseAnother,
  setUsePassword,
  pinId,
  setPinId,
  reInit,
  onReInit,
  cancelVerify,
  setAlertData,
  signUp,
  editProfile,
  email,
  setPhoneNo,
  reRender,
  setLoadingState,
  showHeader = true,
  isInBookingPage,
  bookings,
  visitType,
  discountVerified,
}) {
  const [errors, setErrors] = useState([]);
  const [pin, setPin] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { client_side_country, language_code } = useSelector(({ InitialDataS }) => InitialDataS);
  const realPage = page ? page + " - " : "";

  const user = useSelector(({ InitialData }) => InitialData.initData);

  const submit = async () => {
    try {
      let data;
      dispatch(changeLoading(true));
      if (setLoadingState) setLoadingState(true);
      if (signUp) {
        // this need to be handled
        dispatch(changeLoading(false));
        data = await signup(pinId, pin, bookingNumber, email);
      } else if (editProfile) {
        if (bookingNumber == user?.user_phone_number) {
          setAlertData({
            title: t("Something Went Wrong"),
            message: t("Current and new mobile number are same!"),
            type: "danger",
          });
          // cancelVerify();
          dispatch(changeLoading(false));
          setHasNumber(false);
          return;
        }
        data = await changePhoneNumber(pinId, pin, bookingNumber);
      } else {
        data = await otpLogin(pinId, pin, bookingNumber, email);
        dispatch(GetInitialData({ language_code, client_side_country }));
        window.localStorage.setItem("accessToken", data.token);
        if (reRender) {
          reRender();
        }
      }
      // dispatch(changeLoading(false));
      if (data) {
        if (reInit) {
          dispatch(GetInitialData({ language_code, client_side_country }));
          if (onReInit) {
            onReInit();
          }
        }
        if (editProfile) {
          if (data.success === true) {
            setAlertData({
              title: t("Success!"),
              message: t("Mobile number successfully changed"),
              type: "success",
            });
            setPhoneNo(bookingNumber);
            cancelVerify();
            setHasNumber(false);
            dispatch(changeLoading(false));
          }
          if (data.status === 400) {
            setAlertData({
              title: "Something Went Wrong!",
              message:
                data?.data?.message || (data?.data?.errors?.length ? data.data.errors[0] : "Please contact support"),
              type: "danger",
            });
            dispatch(changeLoading(false));
            return;
          }
        }
        if (isInBookingPage) {
          let trackProps = {
            "Doctor Name": bookings.name_en,
            "Doctor Slug": bookings.slug,
            "Speciality Name": bookings.specialization.name_en,
            "is Premium": bookings.premium,
            "is Discount": discountVerified,
            "Visit Type": visitType,
          };
          Mixpanel.track("Booking, OTP Enter PIN | PIN Verified | Event", trackProps);
        } else {
          Mixpanel.track(`${realPage}OTP PIN Verified`);
        }
      }
    } catch (err) {
      dispatch(changeLoading(false));
      setErrors(err.data.errors);
      dispatch(changeLoading(false));
      if (setLoadingState) {
        setLoadingState(false);
      }
      if (isInBookingPage) {
        let trackProps = {
          "Doctor Name": bookings.name_en,
          "Doctor Slug": bookings.slug,
          "Speciality Name": bookings.specialization.name_en,
          "is Premium": bookings.premium,
          "is Discount": discountVerified,
          "Visit Type": visitType,
        };
        Mixpanel.track("Booking, OTP Enter PIN | PIN Failed to Verify | Event", trackProps);
      } else {
        Mixpanel.track(`${realPage}OTP PIN Failed to Verify`);
      }
    }
  };

  const [counter, { startCountdown }] = useCountdown({
    countStart: 15,
    intervalMs: 1000,
  });

  useEffect(() => {
    startCountdown();
  }, []);

  const [isWhatsappClicked, setIsWhatsappClicked] = useState(false);
  const [isWhatsappSent, setIsWhatsappSent] = useState(false);
  const [isWhatsappError, setIsWhatsappError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isWhatsappDisabled = counter > 0 || isWhatsappClicked;

  useEffect(() => {
    if (!isWhatsappDisabled) {
      Mixpanel.track("OTP Enter PIN | WhatsApp OTP Button Active | Event ", {
        "Page Type": page,
      });
    }
  }, [isWhatsappDisabled]);

  const resendPin = async (isWhatsapp) => {
    setIsLoading(true);
    if (isWhatsapp) {
      setIsWhatsappClicked(true);
      Mixpanel.track("OTP Enter PIN | WhatsApp OTP Button Clicked | Button", {
        "Page Type": page,
      });
    }

    grecaptcha.ready(() => {
      grecaptcha.execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, { action: "submit" }).then(async (token) => {
        try {
          // dispatch(changeLoading(true));
          const data = await resendPinApi(client_side_country, language_code, pinId, token, bookingNumber, isWhatsapp);

          setErrors([]);
          if (data?.pin_id) {
            setPinId(data?.pin_id);
          }

          if (data) {
            if (isInBookingPage) {
              let trackProps = {
                "Doctor Name": bookings.name_en,
                "Doctor Slug": bookings.slug,
                "Speciality Name": bookings.specialization.name_en,
                "is Premium": bookings.premium,
                "is Discount": discountVerified,
                "Visit Type": visitType,
              };
              Mixpanel.track("Booking, OTP Enter PIN | Resend PIN Clicked | Button", trackProps);
            } else {
              if (!isWhatsapp) {
                Mixpanel.track(`${realPage}Resend PIN Clicked`);
              }
            }

            if (isWhatsapp) {
              setIsWhatsappSent(true);
              Mixpanel.track("OTP Enter PIN | WhatsApp OTP Sent | Event", {
                "Page Type": page,
              });
              setAlertData({ title: t("Success!"), message: t("Pin successfully sent by WhatsApp"), type: "success" });
            } else {
              setAlertData({ title: t("Success!"), message: t("OTP has been sent to your number!"), type: "success" });
            }
          }
          // dispatch(changeLoading(false));
        } catch (err) {
          if (err?.status === 429) {
            if (err?.data?.errors || err?.data?.detail) {
              setErrors([t("You have requested the maximum number of allowed OTPs. Please try again in a few hours.")]);
            }
          } else {
            if (isWhatsapp) {
              setIsWhatsappError(true);
              Mixpanel.track("OTP Enter PIN | WhatsApp OTP Failed to Send | Event", {
                "Page Type": page,
              });
              setAlertData({
                title: t("Error"),
                type: "danger",
                message: t("Number not found on WhatsApp"),
              });
            } else {
              if (err?.data?.errors || err?.data?.detail) {
                setErrors(err?.data?.errors || [err?.data?.detail]);
              }
            }
          }
        }
        setIsLoading(false);
      });
    });
  };

  return (
    <>
      {showHeader && (
        <h3 className="desk-only mt-15 otp-verify-header-text">{t("A pin code was sent to your mobile number")}</h3>
      )}
      {showHeader && (
        <h3 className="form-disclaimer mobile-only otp-verify-header-text">
          {t("A pin code was sent to your mobile number")}
        </h3>
      )}
      <div className="form-box otp-verify-form">
        <form
          method="post"
          onSubmit={async (e) => {
            e.preventDefault();
            await submit();
          }}
        >
          {errors.length !== 0 && (
            <div className="alert alert-block alert-danger inherit-width">
              <ul>
                {errors.map((error, index) => (
                  <li key={`error-${index}`}>{error}</li>
                ))}
              </ul>
            </div>
          )}
          <div className={`form-box form-with-otp ${editProfile ? "in-edit-profile" : "in-booking-form"}`}>
            <div id="div_id_pin" className="form-group input-with-otp">
              <label htmlFor="id_pin" className="col-form-label requiredField">
                {t("Pin")}
                {/* <span className="asteriskField">*</span> */}
              </label>
              <input
                type="tel"
                name="pin"
                maxLength="255"
                placeholder={t("Enter your PIN")}
                className="textinput textInput form-control"
                required
                id="id_pin"
                onChange={({ target }) => setPin(target.value)}
              />
              <div className={"otp-sms" + (isWhatsappSent || isLoading ? " disabled" : "")}>
                <a
                  href="#"
                  onClick={isWhatsappSent || isLoading ? (e) => e.preventDefault() : async () => await resendPin(false)}
                >
                  {isWhatsappClicked && isWhatsappSent ? t("Pin sent by WhatsApp") : t("resend pin by SMS")}
                </a>
              </div>
            </div>
            <div
              className={
                "otp-whatsapp " + (counter > 0 || isLoading || isWhatsappError || isWhatsappSent ? "disabled" : "")
              }
            >
              <a
                href="#"
                onClick={
                  counter > 0 || isLoading || isWhatsappError || isWhatsappSent
                    ? (e) => e.preventDefault()
                    : async () => await resendPin(true)
                }
              >
                <img className="otp-whatsapp-icon" src="/next-images/whatsapp-otp.svg" alt="Whatsapp" />
                {isWhatsappError
                  ? t("Number not found on WhatsApp")
                  : isWhatsappClicked && isWhatsappSent
                  ? t("Pin sent by WhatsApp")
                  : t("Resend pin by WhatsApp")}{" "}
                {Boolean(counter) && t("whatsapp-in") + " 0:" + (counter < 10 ? "0" + counter : counter)}
              </a>
            </div>
            <input
              type="submit"
              name="verify"
              value={t("Verify My Mobile Number")}
              className={`btn btn-primary form-submit-primary ${
                pin.length ? "booking-otp-cta-enabled" : "booking-otp-cta-disabled"
              } otp-verify-form-button`}
              id="submit-id-sign_up"
            />
          </div>
        </form>
        {/* <div className="text-center">
                    <a onClick={async () => await resendPin()}>
                        {" "}
                        {t("Click here to resend the PIN to")} {bookingNumber}.{" "}
                    </a>
                </div> */}
        {/* {canChooseAnother && <div className="text-center">
                    <a
                        onClick={() => {
                            setHasNumber(false);
                            Mixpanel.track(`${realPage}Change Number Clicked`);
                        }}
                    >
                        {" "}
                        {t("Not your number? Click here choose another.")}{" "}
                    </a>
                </div>}
                {canUsePassword && (
                    <div className="text-center">
                        <a onClick={()=>setUsePassword(true)}> {t("Use password instead?")} </a>
                    </div>
                )} */}
      </div>
    </>
  );
}
export default OtpVerify;
