import { Mixpanel } from "../../utils";

export const trackCopyCoupon = (doctorName, clinicName, discountName) => {
  Mixpanel.track(`My Apts. | Copy Coupon Link Clicked | Button`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};

export const trackCancelModalOpen = (doctorName, clinicName, discountName) => {
  Mixpanel.track(`My Apts. | Cancel Apt. Modal Opened | Modal`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};

export const trackCancelModalClose = (doctorName, clinicName, discountName) => {
  Mixpanel.track(`My Apts. | Cancel Apt. Modal Closed | Modal`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};

export const trackCancelModalContactUs = (doctorName, clinicName, discountName) => {
  Mixpanel.track(`My Apts, Cancel Apt. Modal | Contact Us Clicked | Button`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};

export const trackCancelModalCancelApt = (doctorName, clinicName, discountName) => {
  Mixpanel.track(`My Apts, Cancel Apt. Modal | Cancel My Apt. Clicked | Button`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};
