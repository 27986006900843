import useTranslation from "../../../intl/useTranslation";

function RescheduleModal({clinic, phone, handleCancel}) {
    const { t } = useTranslation();
    return (
        <>
            <div className="meddy-modal-heading">
                <h2>{phone}</h2>
                <i className="meddy-close cancel-icon-modal" onClick={handleCancel} aria-hidden="true"></i>
            </div>
            <div className="meddy-modal-body">
                <p>
                    {t("Please call")} {clinic} {t("to reschedule your appointment.")}
                </p>
            </div>
        </>
    );
}

export default RescheduleModal;
