import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelAppointment } from "../../../data/actions/userAppointments";
import useTranslation from "../../../intl/useTranslation";
import AptCalendarIcon from "../../../public/images/appointment-date.svg";
import {
  trackCancelModalCancelApt,
  trackCancelModalClose,
  trackCancelModalContactUs,
  trackCancelModalOpen,
  trackCopyCoupon,
} from "../../../services/tracker/appointment";
import { copyToClipBoard, getCompleteUrl, getRoute, localize } from "../../../utils";
import { MeddyModal, Notify } from "../../Common";
import { cancelAppointmentWarning } from "../../util";
import CancellationModal from "./CancellationModal";
import RescheduleModal from "./RescheduleModal";
import Status from "./Status";

const copy = require("clipboard-copy");

function AppointmentCard({
  apt,
  showBookAgain,
  incompleteProfile,
  hideFooter,
  hideHeader,
  showRescheduleFooter,
  showRescheduleDate,
}) {
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({ title: "", type: "", message: "" });
  const [openNotify, setOpenNotify] = useState(false);

  const { t } = useTranslation();
  const { client_side_country, language_code, support_number } = useSelector(({ InitialDataS }) => InitialDataS);
  const { push } = useRouter();
  const dispatch = useDispatch();

  const [cancelModalConfirmation, setCancelModalConfirmation] = useState(t("Contact Us"));
  const cancelAppointmentHandler = async () => {
    let reason =
      cancellationReason && cancellationReason !== "Other" ? cancellationReason : otherReason ? otherReason : "";
    if (!reason) {
      return;
    }
    setLoading(true);
    const id = await dispatch(cancelAppointment(apt.id, reason, push, client_side_country));
    setLoading(false);
    if (id) {
      const redirectPath = `/${localize(`me/appointments/cancellation/${id}/`, client_side_country, language_code)}`;
      await push(redirectPath);
    }
  };

  const toggleRescheduleModal = () => setShowRescheduleModal(!showRescheduleModal);

  useEffect(() => {
    setIsMobile(window?.outerWidth <= 600);
  }, []);

  const confirmRescheduleModal = () => {
    setShowRescheduleModal(false);
    if (isMobile) {
      window.open(`tel:${apt.clinic.phone_number}`, "_self");
    }
  };

  const copyLink = (link) => {
    // const el = document.createElement('input');
    // el.value = link;
    // document.body.appendChild(el);
    // el.select();
    // debugger
    copyToClipBoard(link, document);
    // return true;

    // document.execCommand('copy');
    // document.body.removeChild(el);
    setOpenNotify(true);
    setAlertData({ title: t("Success!"), message: t("Link has been copied!"), type: "success" });
    trackCopyCoupon(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
  };

  return (
    <>
      <div className="single-appointment">
        {!hideHeader && (
          <div className="appointment-header">
            {apt.datetimeFrom && (
              <div className="date-container">
                <img className="mr-1" src="/images/appointment-date.svg" alt="No alt" />
                <span className="date">{apt.datetimeFrom}</span>
              </div>
            )}
            <Status
              status={apt.friendly_status.name}
              hideStatuses={false}
              statusEn={apt.friendly_status.name_en}
              videoConsultation={apt.video_consultation}
              clinicConsultation={apt.clinic_consultation}
              audioConsultation={apt.audio_consultation}
              homeConsultation={apt.home_consultation}
              isB1G1={apt.offers_buy_one_get_one_discount}
              isB1G1Booking={apt.redeemed_by_buy_one_get_one_coupon}
              isFlexWrap={true}
              isDiscount={apt.discount_header}
            />
          </div>
        )}

        <div className="appointment-content">
          {showRescheduleDate && (
            <div className="row no-gutters">
              <div className="appointment-date">
                <Image src={AptCalendarIcon} alt="Appointment Calendar Icon" width={22} height={19} />
                <span className="ml-1">{apt.datetimeFrom}</span>
              </div>
            </div>
          )}
          <div className="row no-gutters">
            <a
              style={{
                display: "inline-block",
              }}
              href={
                "/" +
                localize(
                  `/${apt.doctor.slug}`,
                  apt.doctor.locale,
                  ["ng", "ke"].includes(apt.doctor.locale) ? "en" : language_code
                )
              }
              referrerpolicy="no-referrer"
              rel="noreferrer"
            >
              <div className="clip-img img-circle">
                <img
                  src={`${getCompleteUrl(
                    localize(`profilepic/${apt.doctor.slug}/100/100/95`, apt.doctor.locale, language_code)
                  )}`}
                  alt={apt.doctor.name}
                />
              </div>
              <div className="title-circle-container">
                {apt.doctor.licenseNumber && (
                  <p className="license-number">
                    {"License"} #{apt.doctor.licenseNumber}
                  </p>
                )}
                <h3>
                  {apt.doctor.title} {apt.doctor.name}
                </h3>
                <p className="doctor-title">{apt.doctor.specialization}</p>
                <p className="doctor-hospital">{apt.clinic.name}</p>
              </div>
              {apt.offers_buy_one_get_one_discount && (
                <div className="b1g1-copy-button-container">
                  <button
                    type="button"
                    onClick={() => {
                      copyLink(apt.buy_one_get_one_coupon_link);
                    }}
                    disabled={!apt.buy_one_get_one_coupon_link}
                    className={`b1g1-copy-button ${apt.buy_one_get_one_coupon_link ? "active" : "disabled"}`}
                  >
                    {apt.buy_one_get_one_coupon_link && (
                      <img src="/next-images/Icon_Link.svg" style={{ color: "#ededed", height: "20px" }}></img>
                    )}
                    {!apt.buy_one_get_one_coupon_link && (
                      <img src="/next-images/Icon_Link_Grey.svg" style={{ color: "#ededed" }}></img>
                    )}
                    <span className="b1g1-copy">{t("Copy Coupon Link")}</span>
                  </button>

                  {apt.offers_buy_one_get_one_discount && !apt.buy_one_get_one_coupon_link && (
                    <span className="b1g1-copy-button-status">{t("pending confirmation")}</span>
                  )}
                  {apt.offers_buy_one_get_one_discount && apt.buy_one_get_one_coupon_link && (
                    <span className="b1g1-copy-button-status">{apt.buy_one_get_one_coupon_expiry}</span>
                  )}
                </div>
              )}
            </a>
          </div>
        </div>
        {!hideFooter && (
          <div className="row appointment-footer no-gutters">
            {apt.status === "Confirmed" && apt.is_instant && !showBookAgain && (
              <div className="appointment-action reschedule-appointment">
                <Link href={"/" + localize(`/me/appointments/reschedules/${apt.id}`, apt.doctor.locale, language_code)}>
                  <a>
                    {t("Reschedule Appointment")}
                    <i className="meddy-light-chevron chevron-right mobile-only"></i>
                  </a>
                </Link>
              </div>
            )}
            {apt.status === "Confirmed" && !apt.is_instant && !showBookAgain && apt.clinic.phone_number && (
              <div className="appointment-action reschedule-appointment">
                <a onClick={toggleRescheduleModal}>
                  {t("Reschedule Appointment")}
                  <i className="meddy-light-chevron chevron-right mobile-only"></i>
                </a>
                {showRescheduleModal ? (
                  <MeddyModal
                    confirmationText={t("Call Clinic")}
                    close={toggleRescheduleModal}
                    confirmAction={confirmRescheduleModal}
                    customClassName={["reschedule-modal", "meddymodalactive"]}
                  >
                    <RescheduleModal
                      clinic={apt.clinic.name}
                      phone={apt.clinic.phone_number}
                      handleCancel={toggleRescheduleModal}
                    />
                  </MeddyModal>
                ) : null}
              </div>
            )}
            {apt.payment && !apt.paid && !showBookAgain && !apt.payment.expired && (
              <div className="appointment-action pay-for-appointment">
                <Link href={getRoute(client_side_country, `/payments/${apt.payment.payment_uuid}/?promo_code?pc`)}>
                  <a>
                    {t("Pay for Consultation")}
                    <i className="meddy-light-chevron chevron-right mobile-only"></i>
                  </a>
                </Link>
              </div>
            )}
            {apt.paid && apt.video_consultation && apt.video_consultation.appointment_link && !showBookAgain && (
              <div className={`appointment-action join-call ${incompleteProfile ? "incomplete-profile" : ""}`}>
                <div className="desk-only">
                  {!incompleteProfile ? (
                    <Link href={apt.video_consultation.appointment_link}>
                      <a>
                        {t("Join Call")}
                        <i className="meddy-light-chevron chevron-right mobile-only"></i>
                      </a>
                    </Link>
                  ) : (
                    <>
                      <h3>{t("Join Call")}</h3>
                      <p>
                        <Link href={getRoute(client_side_country, `/me/complete_profile/`)}>
                          <a>{t("Complete your profile")}</a>
                        </Link>{" "}
                        {t("to join the call")}
                      </p>
                    </>
                  )}
                </div>
                <div className="mobile-only">
                  {!incompleteProfile ? (
                    <a href={apt.video_consultation.appointment_link}>
                      {t("Join Call")}
                      <i className="meddy-light-chevron chevron-right mobile-only"></i>
                    </a>
                  ) : (
                    <div className="join-call-disable">
                      <a href={getRoute(client_side_country, `/me/complete_profile/`)}>
                        {t("Complete your profile")} <span>{t("to join the call")}</span>
                        <i className="meddy-light-chevron chevron-right mobile-only"></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!showBookAgain && !apt.payment?.expired && (
              <div className="appointment-action cancel-appointment">
                <a
                  onClick={() => {
                    setConfirmCancelModal(!confirmCancelModal);
                    if (confirmCancelModal) {
                      trackCancelModalClose(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                    } else {
                      trackCancelModalOpen(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                    }
                  }}
                >
                  {t("Cancel Appointment")}
                  <i className="meddy-light-chevron chevron-right mobile-only"></i>
                </a>
                {confirmCancelModal && (
                  <MeddyModal
                    close={() => {
                      setConfirmCancelModal(false);
                      trackCancelModalClose(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                    }}
                    confirmationText={cancelModalConfirmation}
                    confirmAction={() => {
                      trackCancelModalContactUs(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                      if (!isMobile) {
                        setCancelModalConfirmation(support_number);
                      } else {
                        window.open(`tel:{{${support_number}}}`);
                      }
                    }}
                    hideConfirmationText={!support_number}
                    customClassName={["cancellation-modal", "meddymodalactive"]}
                    loading={loading}
                    secondaryText={t("Cancel My Appointment")}
                    secondaryAction={() => {
                      setConfirmCancelModal(false);
                      setShowCancellationModal(true);
                      setCancelModalConfirmation(t("Contact Us"));
                      trackCancelModalCancelApt(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                    }}
                  >
                    <div className="meddy-modal-heading">
                      <div className="cancellation-heading">
                        <img src="/images/cancellation.svg" alt="No alt" />
                        <h2>{t("Cancel Appointment?")}</h2>
                      </div>
                      <i
                        data-cy="appointment-modal-close"
                        className="meddy-close cancel-icon-modal"
                        onClick={() => {
                          setConfirmCancelModal(false);
                          trackCancelModalClose(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                        }}
                        aria-hidden="true"
                      ></i>
                      {/* <i className="meddy-close cancel-icon-modal" onClick={close} aria-hidden="true"></i> */}
                    </div>
                    <div className="meddy-modal-body">
                      <p data-cy="cancel-appointment-text" style={{ padding: 0 }}>
                        {t("Are you sure you want to cancel your appointment?")}{" "}
                        {<b>{t(cancelAppointmentWarning(apt))}</b>}
                      </p>
                    </div>
                  </MeddyModal>
                )}
                {showCancellationModal && (
                  <MeddyModal
                    close={() => {
                      setShowCancellationModal(false);
                      trackCancelModalClose(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                    }}
                    confirmationText={t("Yes, Cancel")}
                    confirmAction={cancelAppointmentHandler}
                    customClassName={["cancellation-modal", "meddymodalactive"]}
                    loading={loading}
                  >
                    <CancellationModal
                      close={() => {
                        setShowCancellationModal(false);
                        trackCancelModalClose(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                      }}
                      aptId={apt.id}
                      aptData={cancellationReason}
                      handleOtherReason={({ target }) => setOtherReason(target.value)}
                      handleCancellation={({ target }) => setCancellationReason(target.value)}
                      cancelModal={() => {
                        setShowCancellationModal(false);
                        trackCancelModalClose(apt?.doctor?.name_en, apt?.clinic?.name_en, apt.discount_header);
                      }}
                    />
                  </MeddyModal>
                )}
              </div>
            )}
            {showBookAgain && (
              <div className="appointment-action">
                <Link
                  href={
                    "/" +
                    localize(`bookings/submit_booking/doctor/${apt.doctor.slug}`, apt.doctor.locale, language_code)
                  }
                >
                  <a>
                    <img className="book-now-icon" src="/images/book-now.svg" alt="No alt" />
                    {t("Book again")}
                  </a>
                </Link>
                <i className="meddy-light-chevron chevron-right mobile-only"></i>
              </div>
            )}
          </div>
        )}

        {showRescheduleFooter && (
          <div className="row no-gutters reschedule-appointment-footer">
            <div className="text-left">{t("Previous Date & Time")}</div>
            <div className="text-right">{apt.datetimeFrom}</div>
          </div>
        )}
      </div>
      {openNotify && (
        <Notify
          type={alertData.type}
          message={alertData.message}
          title={alertData.title}
          closeNotification={() => setOpenNotify(false)}
        />
      )}
    </>
  );
}

export default AppointmentCard;
