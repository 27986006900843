import useTranslation from "../../intl/useTranslation";

const BookingHeader = ({
  CouponCodeInfo,
  discountName,
  discountVerified,
  bookings,
  visitType,
  isB1G1,
  isOTP,
  isBMGF,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <h3
        className={`form-instruction-header ${
          CouponCodeInfo && CouponCodeInfo.success ? "b1g1-alert" : ""
        } booking-header-desktop`}
      >
        {discountVerified ? (
          <span className="desk-only">
            {t("You are booking the")} <strong>{discountName}</strong> {t("discount")} {t("with")} {bookings.title}{" "}
            {bookings.name}.
          </span>
        ) : (
          <></>
        )}
        {visitType === "clinic" && !discountVerified ? (
          <span className="desk-only">
            {t("You are booking a clinic visit with")} {bookings.title} {bookings.name}.
          </span>
        ) : (
          <></>
        )}
        {visitType === "video" && !discountVerified ? (
          <span className="desk-only">
            {t("You are booking a video visit with")} {bookings.title} {bookings.name}.
          </span>
        ) : (
          <></>
        )}
      </h3>
      {!isB1G1 && !isOTP && !isBMGF ? (
        <div className="mb-15 otp-header-text"> {t("Please enter your booking details below.")} </div>
      ) : (
        <></>
      )}
      {isOTP && !isBMGF ? <div className="otp-header-text"> {t("Start by entering your mobile number")} </div> : <></>}

      {isBMGF ? <div className="mb-15 otp-header-text bmgf-otp"> {"Verify Mobile Number"} </div> : <></>}
      {isBMGF ? (
        <div className="otp-header-text bmgf-otp-sub-header"> {"Start by entering your mobile number"} </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BookingHeader;
