const shouldCancelWarningAppear = (apt) => {
    // first check
    const B1G1Firstpatient = !!apt.offers_buy_one_get_one_discount;
    const discountCheck = !!apt.discount_header;
    // second check
    const B1G1Secondpatient = !!apt.redeemed_by_buy_one_get_one_coupon;
    // third check
    const instantCheck = !!apt.is_instant;
    const videoCheck = !!apt.video_consultation;
    const paidCheck = !!apt.paid;

    // consolidate check conditions
    const firstCheck = B1G1Secondpatient;
    const secondCheck = B1G1Firstpatient || discountCheck;
    const thirdCheck = instantCheck || videoCheck || paidCheck;

    // values to be returned for each check
    const firstText = "Your free discount will no longer be valid."
    const secondText = "You will not get a refund."
    const thirdText = "A refund will not be made if you have already paid."
    
    let text = ""
    if (firstCheck) text = firstText;
    else if (secondCheck) text = secondText;
    else if (thirdCheck) text = thirdText;

    return text;
}

export default shouldCancelWarningAppear;
