import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { removeImage, switchToID, switchToPassport, uploadDoc } from "../../../data/actions/completeProfile";
import useTranslation from "../../../intl/useTranslation";
import { downloadFile } from "../../../services/completeProfile";
import { localize, Mixpanel } from "../../../utils";

function UploadVerification({ hasVerification, idFront, idBack, passport, totalSteps, showNext, noDocMsg, setErr }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { language_code, client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);

  const { push } = useRouter();
  const appointmentId = null;
  const goToNext = () => {
    hasVerification === 2
      ? Mixpanel.track("Booking Flow | Complete Profile | Button | Finish Passport Upload", {
          Locale: client_side_country || "qatar",
        })
      : Mixpanel.track("Booking Flow | Complete Profile | Button | Finish ID Upload", {
          Locale: client_side_country || "qatar",
        });
    push(`/${localize("me/appointments", client_side_country, language_code)}`);
  };
  return (
    <>
      <div className="container container-inner">
        <div className={`upload ${hasVerification === 1 ? "upload-id" : !hasVerification ? "empty" : ""}`}>
          {hasVerification === 1 ? (
            <>
              <div className={`upload-item card ${idFront ? "uploaded-item" : ""}`} id="id-front">
                {!idFront ? (
                  <h2>Front Side</h2>
                ) : (
                  <>
                    <div className={`img-container ${!idFront.is_image ? "file-upload" : ""}`}>
                      <img alt="ID Image 1" src={idFront.is_image ? idFront.url : "/images/file.svg"} />
                      {!idFront.is_image && (
                        <a href={idFront.url} target="_blank" title={idFront.file_name}>
                          {idFront.file_name}
                        </a>
                      )}
                    </div>
                    <div
                      onClick={async () => {
                        Mixpanel.track("Booking Flow | Uploaded ID Component | Button | Delete ID", {
                          "Page Type": "Complete Profile Page",
                        });
                        await dispatch(removeImage(idFront, "idFront", appointmentId, idBack, idFront));
                      }}
                      className="img-action remove-img"
                    >
                      <img src="/images/icon-close.svg" alt="Remove Image" />
                    </div>
                    <div
                      onClick={async () => {
                        Mixpanel.track("Booking Flow | Uploaded ID Component | Button | Download ID", {
                          "Page Type": "Complete Profile Page",
                        });
                        await downloadFile(idFront, setErr);
                      }}
                      className="img-action download-img"
                    >
                      <img src="/images/Icon_Download Circle.svg" alt="Download File" />
                    </div>
                    <div className="upload-again">
                      <input
                        type="file"
                        accept="application/pdf,image/*"
                        id="id-image-1"
                        style={{ display: "none" }}
                        onChange={async (e) => await dispatch(uploadDoc(e, "id_front"))}
                      />
                      <div
                        onClick={() => {
                          Mixpanel.track("Booking Flow | Uploaded ID Component | Button | Upload ID Again", {
                            "Page Type": "Complete Profile Page",
                          });
                          document.getElementById("id-image-1").click();
                        }}
                      >
                        <img src="/images/icon-try-again.svg" alt="Upload Again" />
                        <span>{t("Upload Again")}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={`upload-item card ${idBack ? "uploaded-item" : ""}`} id="id-back">
                {!idBack ? (
                  <h2>{t("The other side")}</h2>
                ) : (
                  <>
                    <div className={`img-container ${!idBack.is_image ? "file-upload" : ""}`}>
                      <img alt="ID Image 2" src={idBack.is_image ? idBack.url : "/images/file.svg"} />
                      {!idBack.is_image && (
                        <a href={idBack.url} target="_blank" title={idBack.file_name}>
                          {idBack.file_name}
                        </a>
                      )}
                    </div>
                    <div
                      onClick={async () => {
                        Mixpanel.track("Booking Flow | Uploaded ID Component | Button | Delete ID", {
                          "Page Type": "Complete Profile Page",
                        });
                        await dispatch(removeImage(idBack, "idBack", appointmentId, idBack, idFront));
                      }}
                      className="img-action remove-img"
                    >
                      <img src="/images/icon-close.svg" alt="Remove Image" />
                    </div>
                    <div
                      onClick={async () => {
                        Mixpanel.track("Booking Flow | Uploaded ID Component | Button | Download ID", {
                          "Page Type": "Complete Profile Page",
                        });
                        await downloadFile(idBack, setErr);
                      }}
                      className="img-action download-img"
                    >
                      <img src="/images/Icon_Download Circle.svg" alt="Download File" />
                    </div>
                    <div className="upload-again">
                      <input
                        type="file"
                        accept="application/pdf,image/*"
                        id="id-image-2"
                        style={{ display: "none" }}
                        onChange={async (e) => await dispatch(uploadDoc(e, "id_back"))}
                      />
                      <div
                        onClick={() => {
                          Mixpanel.track("Booking Flow | Uploaded ID Component | Button | Upload ID Again", {
                            "Page Type": "Complete Profile Page",
                          });
                          document.getElementById("id-image-2").click();
                        }}
                      >
                        <img src="/images/icon-try-again.svg" alt="Upload Again" />
                        <span>{t("Upload Again")}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : hasVerification === 2 ? (
            <div className={`upload-item card ${passport ? "uploaded-item" : ""}`} id="passport">
              {!passport ? (
                <h2>{t("Passport Image")}</h2>
              ) : (
                <>
                  <div className={`img-container ${!passport.is_image ? "file-upload" : ""}`}>
                    <img
                      alt="Passport Image"
                      className="uploaded-passport"
                      src={passport.is_image ? passport.url : "/images/file.svg"}
                    />
                    {!passport.is_image && (
                      <a href={passport.url} target="_blank" title={passport.file_name}>
                        {passport.file_name}
                      </a>
                    )}
                  </div>
                  <div
                    onClick={async () => {
                      Mixpanel.track("Booking Flow | Uploaded Passport Component | Button | Delete Passport", {
                        "Page Type": "Complete Profile Page",
                      });
                      await dispatch(removeImage(passport, "passport", appointmentId));
                    }}
                    className="img-action remove-img"
                  >
                    <img src="/images/icon-close.svg" alt="Remove Image" />
                  </div>
                  <div
                    onClick={async () => {
                      Mixpanel.track("Booking Flow | Uploaded Passport Component | Button | Download Passport", {
                        "Page Type": "Complete Profile Page",
                      });
                      await downloadFile(passport, setErr);
                    }}
                    className="img-action download-img"
                  >
                    <img src="/images/Icon_Download Circle.svg" alt="Download File" />
                  </div>
                  <div className="upload-again">
                    <input
                      type="file"
                      accept="application/pdf,image/*"
                      id="passport-image-1"
                      style={{ display: "none" }}
                      onChange={async (e) => await dispatch(uploadDoc(e, "passport", appointmentId))}
                    />
                    <div
                      onClick={() => {
                        Mixpanel.track("Booking Flow | Uploaded Passport Component | Button | Upload Passport Again", {
                          "Page Type": "Complete Profile Page",
                        });
                        document.getElementById("passport-image-1").click();
                      }}
                    >
                      <img src="/images/icon-try-again.svg" alt="Upload Again" />
                      <span>{t("Upload Again")}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : !hasVerification ? (
            <div>
              <img src="/images/icon-upload.svg" alt="Upload Img" />
              <h2>{t(noDocMsg)}</h2>
            </div>
          ) : null}
        </div>
        {hasVerification === 1 ? (
          <p className="skip-title">
            {t("No ID?")}{" "}
            <span
              onClick={async () => {
                Mixpanel.track("Booking Flow | Complete Profile | Button | Upload Passport Instead of ID", {
                  Locale: client_side_country || "qatar",
                });
                dispatch(switchToPassport(idFront, idBack));
              }}
            >
              {t("Upload Passport")}
            </span>
          </p>
        ) : hasVerification === 2 ? (
          <p className="skip-title">
            {t("No Passport?")}{" "}
            <span
              onClick={async () => {
                Mixpanel.track("Booking Flow | Complete Profile | Button | Upload ID Instead of Passport", {
                  Locale: client_side_country || "qatar",
                });
                dispatch(switchToID(passport));
              }}
            >
              {t("Upload ID")}
            </span>
          </p>
        ) : null}
      </div>
      <div className="desk-only sticky-bottom footer complete-profile-footer">
        <div className="container">
          {!hasVerification ? (
            <>
              <p>{t("Back & Front Required")}</p>
              <div>
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  id="id-image-1"
                  style={{ display: "none" }}
                  onChange={async (e) => await dispatch(uploadDoc(e, "id_front"))}
                />
                <input
                  type="button"
                  value={t("Upload ID")}
                  onClick={() => {
                    Mixpanel.track("Booking Flow | Complete Profile | Button | Upload ID");
                    document.getElementById("id-image-1").click();
                  }}
                  className="button-default-md inherit-width upload-btn"
                />

                <input
                  type="file"
                  accept="application/pdf,image/*"
                  id="passport-image-1"
                  style={{ display: "none" }}
                  onChange={async (e) => await dispatch(uploadDoc(e, "passport", appointmentId))}
                />
                <input
                  type="button"
                  value={t("Upload Passport")}
                  onClick={() => {
                    Mixpanel.track("Booking Flow | Complete Profile | Button | Upload Passport");
                    document.getElementById("passport-image-1").click();
                  }}
                  className="button-default-md inherit-width upload-btn"
                />
              </div>
            </>
          ) : hasVerification === 1 || hasVerification === 2 ? (
            <div>
              {/* <!-- UPLOAD ID FRONT --> */}
              {hasVerification === 1 && !idFront && idBack && (
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  id="id-image-1"
                  style={{ display: "none" }}
                  onChange={async (e) => await dispatch(uploadDoc(e, "id_front"))}
                />
              )}
              {hasVerification === 1 && !idFront && idBack && (
                <input
                  type="button"
                  value={t("Upload Front Side")}
                  onClick={() => {
                    Mixpanel.track("Booking Flow | Complete Profile | Button | Upload Front ID Side");
                    document.getElementById("id-image-1").click();
                  }}
                  className="button-default-md inherit-width upload-btn"
                />
              )}

              {/* <!-- UPLOAD ID BACK --> */}
              {hasVerification === 1 && idFront && !idBack && (
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  id="id-image-2"
                  style={{ display: "none" }}
                  onChange={async (e) => await dispatch(uploadDoc(e, "id_back"))}
                />
              )}
              {hasVerification === 1 && idFront && !idBack && (
                <input
                  type="button"
                  value={t("Upload the Other Side")}
                  onClick={() => {
                    Mixpanel.track("Booking Flow | Complete Profile | Button | Upload Other ID Side", {
                      Locale: client_side_country || "qatar",
                    });

                    document.getElementById("id-image-2").click();
                  }}
                  className="button-default-md inherit-width upload-btn"
                />
              )}
              {((hasVerification === 2 && passport) || (hasVerification === 1 && idFront && idBack)) && showNext && (
                <button type="submit" className="button-default-md default-dark inherit-width" onClick={goToNext}>
                  {totalSteps > 1 ? t("Next Step") : t("Finish")}
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="mobile-only sticky-bottom footer extra-margin-footer">
        {!hasVerification ? (
          <>
            <p>{t("Back & Front Required")}</p>
            <div>
              <input
                type="file"
                accept="application/pdf,image/*"
                id="id-image-1"
                style={{ display: "none" }}
                onChange={async (e) => await dispatch(uploadDoc(e, "id_front"))}
              />
              <input
                type="button"
                value={t("Upload ID")}
                onClick={() => {
                  Mixpanel.track("Booking Flow | Complete Profile | Button | Upload ID");
                  document.getElementById("id-image-1").click();
                }}
                className="button-default-md inherit-width upload-btn"
              />

              <input
                type="file"
                accept="application/pdf,image/*"
                id="passport-image-1"
                style={{ display: "none" }}
                onChange={async (e) => await dispatch(uploadDoc(e, "passport", appointmentId))}
              />
              <input
                type="button"
                value={t("Upload Passport")}
                onClick={() => {
                  Mixpanel.track("Booking Flow | Complete Profile | Button | Upload Passport");
                  document.getElementById("passport-image-1").click();
                }}
                className="button-default-md inherit-width upload-btn"
              />
            </div>
          </>
        ) : hasVerification === 1 || hasVerification === 2 ? (
          <div>
            {/* <!-- UPLOAD ID FRONT --> */}
            {hasVerification === 1 && !idFront && idBack && (
              <>
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  id="id-image-1"
                  style={{ display: "none" }}
                  onChange={async (e) => await dispatch(uploadDoc(e, "id_front"))}
                />
                <input
                  type="button"
                  value={t("Upload Front Side")}
                  onClick={() => {
                    Mixpanel.track("Booking Flow | Complete Profile | Button | Upload Front ID Side");
                    document.getElementById("id-image-1").click();
                  }}
                  className="button-default-md inherit-width upload-btn"
                />
              </>
            )}

            {/* <!-- UPLOAD ID BACK --> */}
            {hasVerification === 1 && idFront && !idBack && (
              <>
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  id="id-image-2"
                  style={{ display: "none" }}
                  onChange={async (e) => await dispatch(uploadDoc(e, "id_back"))}
                />
                <input
                  type="button"
                  value={t("Upload the Other Side")}
                  onClick={() => {
                    Mixpanel.track("Booking Flow | Complete Profile | Button | Upload Other ID Side", {
                      Locale: client_side_country || "qatar",
                    });
                    document.getElementById("id-image-2").click();
                  }}
                  className="button-default-md inherit-width upload-btn"
                />
              </>
            )}
            {((hasVerification === 2 && passport) || (hasVerification === 1 && idFront && idBack)) && showNext && (
              <button type="submit" className="button-default-md default-dark inherit-width" onClick={goToNext}>
                {totalSteps > 1 ? t("Next Step") : t("Finish")}
              </button>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default UploadVerification;
