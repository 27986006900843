import React from 'react'
import Link from "next/link";
import { useSelector } from "react-redux";
import { getRoute } from "../../../utils";
import useTranslation from "../../../intl/useTranslation";

function NoAppointmentCard({text, subText, showBookingLink=false}) {
    const { client_side_country} = useSelector(({ InitialDataS }) => InitialDataS);
     const { t } = useTranslation();
    return (
    <div className="single-appointment text-center py-5 no-appointment-available">
        <img
            className="visited-icon"
            src="/images/visited-icon.svg"
            alt="No alt"
        />
        <img
            className="mobile-visited-icon"
            src="/images/mobile-visited-icon.svg"
            alt="No alt"
        />
        <p className="pt-30px text-mueted-1">
            {text}
        </p>
        <p className="pb-30px text-mueted-0">
            {subText}
        </p>
        {showBookingLink && (
            <Link href={getRoute(client_side_country, `/doctors/`)}>
                <a>
                    <button
                        type="button"
                        name="button"
                        className="btn btn-primary-o"
                    >
                        {t("Start booking!")}
                    </button>
                </a>
            </Link>
        )}
    </div>
    )
}

export default NoAppointmentCard
