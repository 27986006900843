import useTranslation from "../../../intl/useTranslation";

function CancellationModal({ aptId, aptData, handleCancellation, handleOtherReason, close, cancelModal}) {

    const { t } = useTranslation();

    return (
        <>
            <div className="meddy-modal-heading">
                <div className="cancellation-heading">
                    <img src="/images/cancellation.svg" alt="No alt" />
                    <h2>{t("Cancel Appointment?")}</h2>
                </div>
                <i className="meddy-close cancel-icon-modal" onClick={cancelModal} aria-hidden="true"></i>
                {/* <i className="meddy-close cancel-icon-modal" onClick={close} aria-hidden="true"></i> */}
            </div>
            <form name="aptForm">
                <div className="meddy-modal-body">
                    <p>{t("Please choose a reason for cancellation.")}</p>
                    <div className="row">
                        <h3 className="modal-cancellation-subheader">{t("Reason for cancellation:")}</h3>
                        <div className="col-xs-12 col-md-6">
                            <div className="form-group radio-default-group">
                                <div className="insurance-radio-buttons radio-button-group border-0 d-inline-grid py-0">
                                    <div className="default-radio">
                                        <label className="mb-0" id="yesLabel" htmlFor={`workRelatedButton-${aptId}`}>
                                            {t("Work Related Reasons")}
                                        </label>
                                        <input
                                            type="radio"
                                            id={`workRelatedButton-${aptId}`}
                                            value="Work related reasons"
                                            name={`insurance-${aptId}`}
                                            onChange={handleCancellation}
                                            checked={aptData === "Work related reasons"}
                                        />
                                        <div className="check check-cancel"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="form-group radio-default-group">
                                <div className="insurance-radio-buttons radio-button-group border-0 d-inline-grid py-0">
                                    <div className="default-radio">
                                        <label
                                            className="mb-0 label-align"
                                            id="noLabel"
                                            htmlFor={`personalEmergencyButton-${aptId}`}
                                        >
                                            {t("Personal Emergency")}
                                        </label>
                                        <input
                                            type="radio"
                                            id={`personalEmergencyButton-${aptId}`}
                                            value="Personal emergency"
                                            name={`insurance-${aptId}`}
                                            onChange={handleCancellation}
                                            checked={aptData === "Personal emergency"}
                                        />
                                        <div className="check check-cancel left-align"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="form-group radio-default-group">
                                <div className="insurance-radio-buttons radio-button-group border-0 d-inline-grid py-0">
                                    <div className="default-radio">
                                        <label
                                            className="mb-0"
                                            id="transLabel"
                                            htmlFor={`lackOfTransportation-${aptId}`}
                                        >
                                            {t("Lack of Transportation")}
                                        </label>
                                        <input
                                            type="radio"
                                            id={`lackOfTransportation-${aptId}`}
                                            value="Lack of transpostation"
                                            name={`insurance-${aptId}`}
                                            onChange={handleCancellation}
                                            checked={aptData === "Lack of transpostation"}
                                        />
                                        <div className="check check-cancel"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="form-group radio-default-group">
                                <div className="insurance-radio-buttons radio-button-group border-0 d-inline-grid py-0">
                                    <div className="default-radio">
                                        <label className="mb-0 label-align" id="sickLabel" htmlFor={`sick-${aptId}`}>
                                            {t("Don't feel sick")}
                                        </label>
                                        <input
                                            type="radio"
                                            id={`sick-${aptId}`}
                                            value="Don't feel sick"
                                            name={`insurance-${aptId}`}
                                            onChange={handleCancellation}
                                            checked={aptData === "Don't feel sick"}
                                        />
                                        <div className="check check-cancel left-align"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="form-group radio-default-group">
                                <div className="insurance-radio-buttons radio-button-group border-0 d-inline-grid py-0">
                                    <div className="default-radio">
                                        <label className="mb-0" id="anymoreLabel" htmlFor={`anymore-${aptId}`}>
                                            {t("Not in pain anymore")}
                                        </label>
                                        <input
                                            type="radio"
                                            id={`anymore-${aptId}`}
                                            value="Not in pain anymore"
                                            name={`insurance-${aptId}`}
                                            onChange={handleCancellation}
                                            checked={aptData === "Not in pain anymore"}
                                        />
                                        <div className="check check-cancel"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="form-group radio-default-group">
                                <div className="insurance-radio-buttons radio-button-group border-0 d-inline-grid py-0">
                                    <div className="default-radio">
                                        <label className="mb-0 label-align" id="otherLabel" htmlFor={`other-${aptId}`}>
                                            {t("Other")}
                                        </label>
                                        <input
                                            type="radio"
                                            id={`other-${aptId}`}
                                            value="Other"
                                            name={`insurance-${aptId}`}
                                            onChange={handleCancellation}
                                            checked={aptData === "Other"}
                                        />
                                        <div className="check check-cancel left-align"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {aptData === "Other" && (
                            <>
                                <div className="col-md-12 col-xs-12 mobile-float-right">
                                    <div className="form-group">
                                        <textarea
                                            onChange={handleOtherReason}
                                            name="other-reason"
                                            rows="2"
                                            className="form-control other-reason"
                                            placeholder={t("Type your reason here")}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12 text-danger col-xs-11 mobile-float-right">
                                    {t("Please choose a reason for cancellation.")}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </form>
        </>
    );
}

export default CancellationModal;
