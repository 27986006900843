import { useDispatch } from "react-redux";
import useTranslation from "../../../intl/useTranslation";
import { uploadDoc, removeImage } from "../../../data/actions/completeProfile";
import { downloadFile } from "../../../services/completeProfile";

function UploadRecords({ medicalRecords, noDocMsg, setErr }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <>
            <div className="container container-inner">
                <div className={`upload upload-records ${medicalRecords.length ? "upload-id" : "empty"}`}>
                    {medicalRecords.length ? (
                        medicalRecords.map((record, index) => (
                            <div className="upload-item card uploaded-item" key={index}>
                                <div className={`img-container ${!record.is_image ? "file-upload" : ""}`}>
                                    <img src={record.is_image ? record.url : "/images/file.svg"} alt="ID Image 1" />
                                    {!record.is_image && (
                                        <a href={record.url} target="_blank" title={record.file_name}>
                                            {record.file_name}
                                        </a>
                                    )}
                                </div>
                                <div
                                    onClick={async () => await dispatch(removeImage(record, "record", null))}
                                    className="img-action remove-img"
                                >
                                    <img src="/images/icon-close.svg" alt="Remove Image" />
                                </div>
                                <div
                                    onClick={async () => await downloadFile(record, setErr)}
                                    className="img-action download-img"
                                >
                                    <img src="/images/Icon_Download Circle.svg" alt="Download File" />
                                </div>
                                <div className="upload-again">
                                    <input
                                        type="file"
                                        accept="application/pdf,image/*"
                                        id="upload-record-btn-again"
                                        style={{ display: "none" }}
                                        onChange={async (e) => await dispatch(uploadDoc(e, "medical_record", null))}
                                    />
                                    <div onClick={() => document.getElementById("upload-record-btn-again").click()}>
                                        <img src="/images/icon-try-again.svg" alt="Upload Again" />
                                        <span>{t("Upload Again")}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>
                            <img src="/images/icon-upload.svg" alt="Upload Img" />
                            <h2>{t(noDocMsg)}</h2>
                        </div>
                    )}
                </div>
            </div>
            <div className="desk-only sticky-bottom footer complete-profile-footer">
                <div className="container">
                    <div>
                        <input
                            type="file"
                            accept="application/pdf,image/*"
                            id="upload-record-btn"
                            style={{ display: "none" }}
                            onChange={async (e) => await dispatch(uploadDoc(e, "medical_record", null))}
                        />
                        <input
                            type="button"
                            value={!medicalRecords.length ? t("Upload Medical Reports") : t("Upload Another")}
                            onClick={() => document.getElementById("upload-record-btn").click()}
                            className="button-default-md inherit-width upload-btn"
                        />
                    </div>
                </div>
            </div>
            <div className="mobile-only sticky-bottom footer extra-margin-footer">
                <div>
                    <input
                        type="file"
                        accept="application/pdf,image/*"
                        id="upload-record-btn"
                        style={{ display: "none" }}
                        onChange={async (e) => await dispatch(uploadDoc(e, "medical_record", null))}
                    />
                    <input
                        type="button"
                        value={!medicalRecords.length ? t("Upload Medical Reports") : t("Upload Another")}
                        onClick={() => document.getElementById("upload-record-btn").click()}
                        className="button-default-md inherit-width upload-btn"
                    />
                </div>
            </div>
        </>
    );
}

export default UploadRecords;
