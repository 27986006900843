import Link from "next/link";
import { useSelector } from "react-redux";
import useTranslation from "../../../intl/useTranslation";
import { getRoute } from "../../../utils";

function CompleteProfileCard({ customClassName }) {
    const { t } = useTranslation();
    const { client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);

    return (
        <div className={`complete-profile-card ${customClassName ? customClassName : ""}`}>
            <div className="complete-profile-header">
                <h3>{t("Complete Profile")}</h3>
                <p>
                    {t("Completing your profile is")}
                    <strong> {t("mandatory")}</strong> {"     "}
                    {t("before an appointment.")}
                </p>
            </div>
            <div>
                <div>
                    <Link href={getRoute(client_side_country, `/me/complete_profile/`)}>
                        <a type="submit" className="button-default-md default-dark complete-profile-btn">
                            {t("Complete My Profile")}
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default CompleteProfileCard;
